import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { forkJoin, of } from 'rxjs';
import { BrandNatoCageCode } from 'src/app/enums/BrandNatoCageCode.enum';


@Injectable({
  providedIn: 'root'
})
export class ImpulsesService {

  baseUrl = environment.baseUrl
  xApiKey = environment.xApiKey

  constructor(private tokenService: TokenService, private httpClient: HttpClient) { }

  getImpulses(pageNo: number, pageSize: number, purchaserCode: string, partNumber: string, mrCode: string) {
    const headers = {
      'x-api-key': this.xApiKey,
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
    return this.httpClient.get(this.baseUrl + `/requisitions/D40/2536269/350676/0/20`, { headers });
  }

  getUrlForFetchingImpulses(pageNo: number, pageSize: number, purchaserCode: string, partNumber: string, mrCode: string, packageId: string, state: string, type: string, userId: string) {
    if ((partNumber != '') && (purchaserCode == '' && mrCode == '' && packageId == '' && state == '' && type == '')) {
      const partNoApi = this.httpClient.get(this.baseUrl + `/requisition_partnumber?partNumber=${partNumber}&userId=${userId}&pageNo=${pageNo}&pageSize=${pageSize}`)
      const partNoCount = this.httpClient.get(this.baseUrl + `/requisition_partnumber_count?partNumber=${partNumber}&userId=${userId}`)
      return forkJoin([partNoApi, partNoCount]);
    } else if ((mrCode != '') && (purchaserCode == '' && partNumber == '' && packageId == '' && state == '' && type == '')) {
      const mrCodeApi = this.httpClient.get(this.baseUrl + `/requisition_mrcode?mrCode=${mrCode}&userId=${userId}&pageSize=${pageSize}&pageNo=${pageNo}`)
      const mrCodeCount = this.httpClient.get(this.baseUrl + `/requisition_mrcode_count?mrCode=${mrCode}&userId=${userId}`)
      return forkJoin([mrCodeApi, mrCodeCount]);
    } else if ((purchaserCode != '') && (mrCode == '' && partNumber == '' && packageId == '' && state == '' && type == '')) {
      const purchaserCodeApi = this.httpClient.get(this.baseUrl + `/requisition_purchaser?purchaserCode=${purchaserCode}&userId=${userId}&pageSize=${pageSize}&pageNo=${pageNo}`)
      const purchaserCodeCount = this.httpClient.get(this.baseUrl + `/requisition_purchaser_count?purchaserCode=${purchaserCode}&userId=${userId}`)
      return forkJoin([purchaserCodeApi, purchaserCodeCount]);
    } else if ((packageId != '') && (mrCode == '' && partNumber == '' && purchaserCode == '' && state == '' && type == '')) {
      const packageIdApi = this.httpClient.get(this.baseUrl + `/requisition_packageid?packageId=${packageId}&pageSize=${pageSize}&pageNo=${pageNo}&userId=${userId}`)
      const packageIdCount = this.httpClient.get(this.baseUrl + `/requisition_packageid_count?packageId=${packageId}&userId=${userId}`)
      return forkJoin([packageIdApi, packageIdCount])
    }
    // } else if ((state != '') && (mrCode == '' && partNumber == '' && purchaserCode == '' && packageId == '')) {
    //   const packageIdApi = this.httpClient.get(this.baseUrl + `/requisition_reqstate?reqStateCode=${state}&pageSize=${pageSize}&pageNo=${pageNo}&userId=${userId}`)
    //   const packageIdCount = this.httpClient.get(this.baseUrl + `/requisition_reqstate_count?reqStateCode=${state}&userId=${userId}`)
    //   return forkJoin([packageIdApi, packageIdCount])
    // } 
    // else if ((type != '') && (mrCode == '' && partNumber == '' && purchaserCode == '' && packageId == '' && state == '')) {
    //   const packageIdApi = this.httpClient.get(this.baseUrl + `/requisition_reqstate?reqStateCode=${state}&pageSize=${pageSize}&pageNo=${pageNo}&userId=${userId}`)
    //   const packageIdCount = this.httpClient.get(this.baseUrl + `/requisition_reqstate_count?reqStateCode=${state}&userId=${userId}`)
    //   return forkJoin([packageIdApi, packageIdCount])
    // }
    else if (state != '' || packageId != '' || mrCode != '' || purchaserCode != '' || partNumber != '' || type == '') {
      const requisitions = this.httpClient.get(this.baseUrl + `/get_requisitions?purchaserCode=${purchaserCode}&packageId=${packageId}&mrCode=${mrCode}&partNumber=${partNumber}&reqStateCode=${state}&reqType=${type}&pageNo=${pageNo}&pageSize=${pageSize}&userId=${userId}`);
      const requisitionsCount = this.httpClient.get(this.baseUrl + `/get_requisitions_pagination?purchaserCode=${purchaserCode}&mrCode=${mrCode}&partNumber=${partNumber}&packageId=${packageId}&reqStateCode=${state}&reqType=${type}&userId=${userId}`);
      return forkJoin([requisitions, requisitionsCount])
    }
    else {
      return of([{}, {}])
    }
  }

  getRequisitions(purchaserCode: any, packageId: any, mrCode: any, partNumber: any, pageNo: any, pageSize: any, userId: any) {
    return this.httpClient.get(this.baseUrl + `/get_requisitions?purchaserCode=${purchaserCode}&packageId=${packageId}&mrCode=${mrCode}&partNumber=${partNumber}&pageNo=${pageNo}&pageSize=${pageSize}&userId=${userId}`)
  }

  getRequisitionsPagination(purchaserCode: any, mrCode: any, partNumber: any, packageId: any, userId: any) {
    return this.httpClient.get(this.baseUrl + `/get_requisitions_pagination?purchaserCode=${purchaserCode}&mrCode=${mrCode}&partNumber=${partNumber}&packageId=${packageId}&userId=${userId}`)
  }

  getManPartNo(partNo: any) {
    return this.httpClient.get(this.baseUrl + `/get_brand_part_number?partNumber=${partNo}`)
  }

  getBrandPartNoMapping(uniquePartNos: string[], responses: any[], rows: any[]): any[] {
    const partNoToMANPartNoMap: { [key: string]: string } = {};
    const partNoToNavistarPartNoMap: { [key: string]: string } = {};
    uniquePartNos.forEach((partNo, index) => {
      const item = responses[index];
      let NavistarbrandpartNo = '';
      let MANbrandpartNo = '';
      if (item && item.partners) {
        let MANpartner;
        let NavistarPartner
        //if(orderType == 'EXN'){
          NavistarPartner = item.partners.find((p: any) => p.brand === BrandNatoCageCode.NAVISTAR);
        //}else{
          MANpartner = item.partners.find((p: any) => p.brand === BrandNatoCageCode.MAN);
        //}
        if (NavistarPartner) {
          NavistarbrandpartNo = NavistarPartner.objectId ? NavistarPartner.objectId : '';
        }
        if (MANpartner) {
          MANbrandpartNo = MANpartner.objectId ? MANpartner.objectId : '';
        }
      }
      partNoToMANPartNoMap[partNo] = MANbrandpartNo;
      partNoToNavistarPartNoMap[partNo] = NavistarbrandpartNo;
    });
    return rows.map(row => ({
      ...row,
      NavistarPartNo: partNoToNavistarPartNoMap[row.PARTNO] || '',
      MANPartNo: partNoToMANPartNoMap[row.PARTNO] || ''
    }));
  }

  getBrandPartNoMappingForOrders(uniquePartNos: string[], responses: any[], rows: any[], orderType :string): any[] {
    const partNoToBrandPartNoMap: { [key: string]: string } = {};
    uniquePartNos.forEach((partNo, index) => {
      const item = responses[index];
      let brandpartNo = '';
      if (item && item.partners) {
        let partner;
        if(orderType == 'EXN'){
          partner = item.partners.find((p: any) => p.brand === BrandNatoCageCode.NAVISTAR);
        }else{
          partner = item.partners.find((p: any) => p.brand === BrandNatoCageCode.MAN);
        }
        if (partner) {
          brandpartNo = partner.objectId ? partner.objectId : '';
        }
      }
      partNoToBrandPartNoMap[partNo] = brandpartNo;
    });
    return rows.map(row => ({
      ...row,
      BrandPartNo: partNoToBrandPartNoMap[row.PARTNO] || ''
    }));
  }

  getFeatureFlag() {
    return this.httpClient.get(this.baseUrl + `/get_feature_flags`)
  }

  getPartPriceHistory() {
    
  }

}

